import {makeStyles} from "tss-react/mui";

export default makeStyles()(({palette: {common, grey, background}, custom: {preparation, post, progress}, spacing, typography}) => ({
    backgroundArea: {
        backgroundColor: grey[400]
    },
    backgroundEnter: {
        backgroundColor: preparation.main
    },
    backgroundOp: {
        backgroundColor: progress.main
    },
    backgroundExit: {
        backgroundColor: post.main
    },
    label: {
        marginBottom: spacing("xs")
    },

    rowStartEnd: {
        justifyContent: "space-between",
        marginBottom: spacing("s"),
        marginLeft: "152px"
    },
    rowTime: {
        fontSize: "0.75rem",
        color: grey[800],
        display: "flex",
        overflow: "hidden"
    },
    rowTimeItem: {
        marginRight: "32px"
    },
    graphicContainer: {
        borderRight: `1px solid ${grey[100]}`
    },
    graphicRow: {
        color: grey[800],
        display: "flex",
        flexDirection: "row",
        fontSize: "0.75rem",
        height: "52px",
        borderBottom: `1px solid ${grey[100]}`
    },
    graphicRowLabelsCell: {
        display: "flex",
        borderRight: `1px solid ${post.light}`
    },
    graphicRowStart: {
        marginRight: "10px",
        fontWeight: typography.fontWeightBold
    },
    centerText: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    graphicRowLabel: {
        paddingRight: "20px",
        width: "110px"
    },
    graphicRowBar: {height: "18px"},
    graphicRowEven: {
        backgroundColor: background.default
    },
    graphicRowLabelBarWrapper: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1
    },
    borderBottom: {
        color: `${grey[100]}`
    },
    value: {
        fontWeight: typography.fontWeightRegular
    },
    bold: {
        fontWeight: typography.fontWeightBold
    },
    cpb: {
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "160px"
    }
}));
