import {Box, useMediaQuery} from "@mui/material";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {DateContext} from "../../../contexts/dates";
import {selectDetails} from "../../../pages/op_management/op_management_selectors";
import detailsStyles from "../op_details.styles";
import opDetailsFactors from "./op_details_factors.styles";

/**
 * render opDetailsFactors component, currently disabled
 * @return {React.ReactElement}
 */
export const OpDetailsFactors = () => {
    const matchesUpLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const {classes, cx} = detailsStyles();
    const styles = opDetailsFactors();
    const {t} = useTranslation();
    const details = useSelector(selectDetails);
    const {fromMillis} = useContext(DateContext);

    // show nothing if data are missing
    if (!details._internalTimestamps || !details._kiEstimation || !details._kiEstimation.factors) {
        return t("OpDetails.not_available");
    }

    /**
     * Converts amount of seconds to hours, and formats it adding a 0s pad in case it's necessary.
     * @param {number} seconds - The amount of seconds
     * @returns {(number|string)} The formatted amount of hours that
     */

    const secondsToHours = (seconds) => {
        const hours = Math.floor(Math.abs(seconds / 3600));
        const formattedHours = hours <= 9 ? hours.toString().padStart(2, 0) : hours;
        return formattedHours;
    };

    const opFactors = details._kiEstimation.factors;
    const plusFactor = [];
    const minusFactor = [];
    let minusSum = 0;
    let plusSum = 0;

    // loop all op factors and separate plus and minus factors
    for (const opFactor of opFactors) {
        const milliseconds = Math.abs(Object.values(opFactor)[0]) * 1000;
        const opFactorReformatted = {
            name: Object.keys(opFactor)[0],
            value: Object.values(opFactor)[0],
            valueFormatted: fromMillis(milliseconds, "mm:ss")
        };
        if (opFactorReformatted.value >= 0) {
            plusFactor.push(opFactorReformatted);
            plusSum += opFactorReformatted.value;
        } else {
            minusFactor.push(opFactorReformatted);
            minusSum += Math.abs(opFactorReformatted.value);
        }
    }

    const plusFactorsOutput = plusFactor.map(({name, value, valueFormatted}) => ({
        name: t(`OpDetailFactors.${name}`),
        value: `(${secondsToHours(value)} ${t("OpDetails.hours")} \u00A0 ${valueFormatted} ${t("OpDetailFactors.minutesShort")})`
    }));

    const minusFactorsOutput = minusFactor.map(({name, value, valueFormatted}) => ({
        name: t(`OpDetailFactors.${name}`),
        value: `(${secondsToHours(value)} ${t("OpDetails.hours")} \u00A0 ${valueFormatted} ${t("OpDetailFactors.minutesShort")})`
    }));

    const timestamps = details._internalTimestamps;

    // check for valid data and show error message
    if (timestamps.duraRoomLockPost.refEnd === null) {
        return <div className={cx(classes.rowTime, {[classes.mt1]: !matchesUpLg})}>{t("OpDetails.not_available")}</div>;
    }

    // durations
    const durationOp = timestamps.duraRoomLockOp.refEnd - timestamps.duraRoomLockOp.refStart;
    const durationOpMin = Math.round(durationOp / 60);
    const durationOpFormatted = t("OpDetails.hhmm", {hh: secondsToHours(durationOp), mm: durationOpMin % 60});

    // op factors calculations and formatting
    const plusSumInMinutes = fromMillis(plusSum * 1000, "mm:ss");
    const plusSumFormatted = `${secondsToHours(plusSum)} ${t("OpDetails.hours")} \u00a0 ${plusSumInMinutes} ${t("OpDetails.minutes")} `;
    const minusSumInMinutes = fromMillis(minusSum * 1000, "mm:ss");
    const minusSumFormatted = `${secondsToHours(minusSum)} ${t("OpDetails.hours")} \u00a0 ${minusSumInMinutes} ${t("OpDetails.minutes")} `;
    const plusFactorsCount = plusFactor.length;
    const minusFactorsCount = minusFactor.length;

    return (
        <div
            className={cx({
                [styles.container]: matchesUpLg,
                [styles.containerSmall]: !matchesUpLg
            })}
            data-testid="opDetailFactors"
        >
            <div>
                <div className={styles.labelSecondary}>
                    {t("OpDetails.standardOpDuration")} + {t("OpDetails.opFactors")}
                </div>
                <div className={styles.row}>
                    <div className={classes.title}>{`${t("OpDetails.legendOp")} ${t("OpDetails.total")}`}</div>
                    <div className={classes.title}>{durationOpFormatted}</div>
                </div>
            </div>
            <Box borderBottom={1} className={classes.borderBottom} my={1} />
            <div>
                <div className={styles.row}>
                    <div>
                        <b>
                            <span className={styles.totalFactors}>{plusFactorsCount}</span>
                            {t("OpDetails.addingFactors")}:
                        </b>
                    </div>
                    <div className={styles.minusText}>
                        <b>+ {plusSumFormatted}</b>
                    </div>
                </div>
                <ul className={styles.factorsList}>
                    {Boolean(plusFactorsOutput.length) &&
                        plusFactorsOutput.map(({name, value}) => (
                            <li key={name}>
                                <div className={styles.row}>
                                    <div>{name}</div>
                                    <div>{value}</div>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
            <Box borderBottom={1} className={classes.borderBottom} my={1} />
            <div>
                <div className={styles.row}>
                    <div>
                        <b>
                            <span className={styles.totalFactors}>{minusFactorsCount}</span>
                            {t("OpDetails.reducingFactors")}:
                        </b>
                    </div>
                    <div className={styles.plusText}>
                        <b>- {minusSumFormatted}</b>
                    </div>
                </div>
                <ul className={styles.factorsList}>
                    {Boolean(minusFactorsOutput.length) &&
                        minusFactorsOutput.map(({name, value}) => (
                            <li key={name}>
                                <div className={styles.row}>
                                    <div>{name}</div>
                                    <div>{value}</div>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
            <Box borderBottom={1} className={classes.borderBottom} my={1} />
        </div>
    );
};
