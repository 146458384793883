// @ts-check
import {Box} from "@mui/material";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectDetails} from "../../../pages/op_management/op_management_selectors";
import {formatBirthDate} from "../../../utils/format_birth_date";
import {formatSurgeon} from "../../../utils/format_op_team";
import {getParticipantCategories} from "../../../utils/get_participant_category";
import {formatObservation} from "../../../utils/patient_helpers";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {
    selectFullName,
    selectPatientBirthDate,
    selectPatientGender,
    selectStandardNamesArray
} from "../../private_data/private_data_selectors";
import getPractitionerIdsFromScheduleOpByCategory from "../../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import useStyles from "../op_details.styles";

const excludeFromSurgeonsCategories = ["assistant"];

export const OpDetailsOverview = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    // Redux
    const details = useSelector(selectDetails);
    const {participantCategoriesForHealthcareService} = useSelector(selectFeSettings);

    const {formatFromISO, getLuxonToken} = useContext(DateContext);

    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH, HYPHEN}
    } = config;

    const {
        _patient: patient,
        _location: location,
        _anesthesias: anesthesias,
        _priority: priority,
        _healthcareService: healthcareService,
        _devices: devices,
        _actualInterventions: actualInterventions,
        _plannedInterventions: plannedInterventions,
        _internalTimestamps: internalTimestamps,
        _surgeonPresenting: surgeonPresenting,
        /** @type {NextOrOpTeam} */
        _team: team
    } = details;

    const hcServiceId = healthcareService.reference;
    const participantCategories = getParticipantCategories({
        participantCategoriesForHealthcareService,
        hcServiceId
    });
    // Prepare the category for the detail layer
    // In the detail layer, the assistant category should be shown separately, not in the surgeon row. It's different from the op box in the dayview #14353
    const participantCategoriesForDetailLayer = {
        ...participantCategories,
        surgeons: participantCategories.surgeons.filter((category) => !excludeFromSurgeonsCategories.includes(category))
    }; // Set team
    const {
        surgeons: surgeonIds,
        surgeryNurses: surgeryNurseIds,
        anesthesiaNurses: anesthesiaNurseIds,
        anesthesias: anesthesiaIds
    } = getPractitionerIdsFromScheduleOpByCategory(team, participantCategoriesForDetailLayer);
    // @todo this part will be adjusted and improved later in the story #12555
    // jumpers, assistants
    const jumperIds = Object.values(team.jumper || {}).filter(Boolean);
    const assistantIds = Object.values(team.assistant || {}).filter(Boolean);
    const cardiacPerfusionistIds = Object.values(team.cardiacPerfusionist || {}).filter(Boolean);
    const cardiologistIds = Object.values(team.cardiologist || {}).filter(Boolean);

    // add surgeonPresentingId if this id is set and not in the suregonIds
    const allSurgeonIds =
        surgeonPresenting?.reference && !surgeonIds.includes(surgeonPresenting.reference)
            ? surgeonIds.concat(surgeonPresenting.reference)
            : surgeonIds;

    const surgeonNames = useSelector(
        selectStandardNamesArray({ids: allSurgeonIds || [], type: "practitioner"}) // surgeons except assistant
    );

    const anesthetistNames = useSelector(selectStandardNamesArray({ids: anesthesiaIds || [], type: "practitioner"}));
    const patientName = useSelector(selectFullName({id: patient?.id, type: "patient"}));
    const gender = useSelector((state) => selectPatientGender(state, {id: patient?.id}));
    const birthDate = useSelector((state) => selectPatientBirthDate(state, {id: patient?.id}));
    const jumperNames = useSelector(selectStandardNamesArray({ids: jumperIds, type: "practitioner"}));
    const anesthesiaNurseNames = useSelector(selectStandardNamesArray({ids: anesthesiaNurseIds || [], type: "practitioner"}));
    const assistantNames = useSelector(selectStandardNamesArray({ids: assistantIds || [], type: "practitioner"}));
    const instrumentationNames = useSelector(selectStandardNamesArray({ids: surgeryNurseIds || [], type: "practitioner"}));
    const cardiacPerfusionistNames = useSelector(selectStandardNamesArray({ids: cardiacPerfusionistIds || [], type: "practitioner"}));
    const cardiologistNames = useSelector(selectStandardNamesArray({ids: cardiologistIds || [], type: "practitioner"}));

    const roomInfos = useSelector(selectRoomInfos);

    // Timestamps
    const startTimestamp = internalTimestamps?.duraRoomLockPre?.dtStart;
    const labelOpStart = formatFromISO(startTimestamp, DATE_FORMATS.TIME);

    // Set interventions
    const interventions = {...plannedInterventions};
    if (actualInterventions.main && actualInterventions.main.length) {
        interventions.main = [...actualInterventions.main];
    }
    if (actualInterventions.sub && actualInterventions.sub.length) {
        interventions.sub = [...actualInterventions.sub];
    }

    // intervention (surgery name)
    const interventionsValue = interventions.main && interventions.main.length ? interventions.main[0].display : HYPHEN;

    // Devices // @todo check if the structure is correct #15386
    const devicesFiltered = devices
        // @ts-ignore
        .filter(({type}) => type === "Device")
        // @ts-ignore
        .map(({reference, display}) => <div key={reference}>{display}</div>);

    // Op room
    const opRoom = location.display || roomInfos.find(({id}) => id === location.reference)?.name;

    const formatDiagnosis = (data) => {
        return (
            <ul className={classes.diagnosis}>
                {data.map((el, i) => (
                    // the display contents are duplicated, so temporarily allow to use index
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={el.display + "-" + i}>{el.display}</li>
                ))}
            </ul>
        );
    };
    const genderFormatted = gender ? t(`GenderShort.${gender}`, gender) : HYPHEN;
    const patientNameAgeGender = [patientName, formatBirthDate(birthDate, getLuxonToken(DATE_FORMATS.BIRTH_DATE_FORMAT)), genderFormatted];

    // Format observations (weight and height)
    const {height, weight, infectiousStatus} = patient?.observations || {};
    const observationFormatted = [weight, height, infectiousStatus].map(formatObservation).join(` ${VERTICAL_SLASH} `);

    // destruct from the patient.locations
    const {before, after} = patient?.locations || {};
    const ROWS = [
        // Start time and op-room
        {
            label: t("OpDetails.start"),
            value: startTimestamp ? `${t("OpDetails.oclock", {time: labelOpStart})}` : HYPHEN
        },
        {label: t("OpDetails.opRoom"), value: opRoom || HYPHEN},
        {
            label: t("OpDetails.station"),
            value: t("OpDetails.fromTo", {from: before?.ward || HYPHEN, to: after?.ward || HYPHEN}),
            hasBottomBorder: true
        },

        // Patient and additional info.
        {label: t("OpDetails.patient"), value: patientNameAgeGender.join(` ${VERTICAL_SLASH}`)},
        {label: t("OpDetails.observations"), value: observationFormatted},
        {label: t("OpDetails.interventions"), value: interventionsValue},
        {
            label: t("OpDetails.diagnosis"),
            value: (patient?.conditions?.length && formatDiagnosis(patient.conditions)) || HYPHEN
        },
        {label: t("OpDetails.urgency"), value: priority ? t(`urgency.${priority}`) : HYPHEN},
        {
            label: t("OpDetails.anesthesy"),
            value: anesthesias && anesthesias.length ? anesthesias.map((el) => el.display).join(", ") : HYPHEN,
            hasBottomBorder: true
        },
        // Discipline and teams
        {
            label: t("OpDetails.discipline"),
            value: healthcareService?.reference ? t([`HealthcareService.${hcServiceId}`, healthcareService?.display]) : HYPHEN
        },
        {
            label: t("OpDetails.operator"),
            value: surgeonNames.length
                ? surgeonNames
                      .map((nameObj) =>
                          formatSurgeon(nameObj.name, surgeonPresenting?.isAttending, nameObj.id === surgeonPresenting?.reference)
                      )
                      .join(VERTICAL_SLASH)
                : HYPHEN
        },
        {
            label: t("OpDetails.opNurse"),
            value: instrumentationNames.length ? instrumentationNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN
        },
        {
            label: t("OpDetails.springer"),
            value: jumperNames.length ? jumperNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN
        },
        {
            label: t("OpDetails.anesthetist"),
            value: anesthetistNames.length ? anesthetistNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN
        },
        {
            label: t("OpDetails.anesthesiaNurses"),
            value: anesthesiaNurseNames.length ? anesthesiaNurseNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN
        },
        {
            label: t("OpDetails.assistant"),
            value: assistantNames.length ? assistantNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN
        },
        {
            label: t("OpDetails.cardiacPerfusionist"),
            value: cardiacPerfusionistNames.length ? cardiacPerfusionistNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN
        },
        {
            label: t("OpDetails.cardiologist"),
            value: cardiologistNames.length ? cardiologistNames.map((nameObj) => nameObj.name).join(VERTICAL_SLASH) : HYPHEN,
            hasBottomBorder: true
        },

        // Siebe
        {label: t("OpDetails.siebe"), value: devices && devices.length ? devicesFiltered : HYPHEN}
    ];
    return (
        <div className={classes.innerScrollableContent} data-testid="overviewContent">
            {ROWS.map(({value, label, hasBottomBorder, hideRow}) => (
                <div key={label}>
                    {!hideRow && (
                        <div>
                            <div className={classes.row}>
                                <div className={classes.label}>{label}:</div>
                                <div className={classes.value} data-testid={label}>
                                    {value}
                                </div>
                            </div>
                            {hasBottomBorder && <Box borderBottom={1} className={classes.borderBottom} my={1} />}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
