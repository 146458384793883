// @ts-check
/**
 * Check if the internalTimestamps are set
 * @param {InternalTimestampsOpData} internalTimestamps
 * @return {boolean}
 */
export const isTimestampsSet = (internalTimestamps) =>
    Boolean(internalTimestamps?.duraRoomLockPre?.dtStart) &&
    Boolean(internalTimestamps?.duraRoomLockPre?.dtEnd) &&
    Boolean(internalTimestamps?.duraRoomLockOp?.dtStart) &&
    Boolean(internalTimestamps?.duraRoomLockOp?.dtEnd) &&
    Boolean(internalTimestamps?.duraRoomLockPost?.dtStart) &&
    Boolean(internalTimestamps?.duraRoomLockPost?.dtEnd);
