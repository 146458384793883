// @ts-check
import {Box} from "@mui/material";
import React, {Fragment, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {completedStatus} from "../../../../config/op_status";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import useRoundedDateTime from "../../../hooks/use_rounded_datetime";
import {selectDetails} from "../../../pages/op_management/op_management_selectors";
import {isUndefinedOrNull} from "../../../utils/is_undefined_or_null";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {Cpb} from "../../shared/cpb";
import {isTimestampsSet} from "../helpers";
import useStyles from "./op_details_duration.styles";

const CPB = "CPB";

export const OpDetailsDuration = () => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();

    const details = useSelector(selectDetails);
    const {additionalOpInfo} = useSelector(selectFeSettings);

    const {diffDT, format} = useContext(DateContext);

    const timestamps = details._internalTimestamps;

    const {roundedDT: opEnterRounded} = useRoundedDateTime(timestamps?.duraRoomLockPre?.dtStart);
    const {roundedDT: opExitRounded} = useRoundedDateTime(timestamps?.duraRoomLockPost?.dtEnd);
    const {roundedDT: opStartRounded} = useRoundedDateTime(timestamps?.duraRoomLockOp?.dtStart);
    const {roundedDT: opEndRounded} = useRoundedDateTime(timestamps?.duraRoomLockOp?.dtEnd);
    const {roundedDT: opAreaPreRounded} = useRoundedDateTime(timestamps?.duraNoLockPre?.dtStart);
    const {roundedDT: opAreaPostRounded} = useRoundedDateTime(timestamps?.duraNoLockPost?.dtEnd);

    // check for valid data and show error message
    if (!isTimestampsSet(timestamps)) {
        return <div className={classes.rowTime}>{t("OpDetails.not_available")}</div>;
    }

    // labels
    const labelOpEnter = opEnterRounded && format(opEnterRounded, DATE_FORMATS.TIME);
    const labelOpExit = opExitRounded && format(opExitRounded, DATE_FORMATS.TIME);
    const labelOpStart = opStartRounded && format(opStartRounded, DATE_FORMATS.TIME);
    const labelOpEnd = opEndRounded && format(opEndRounded, DATE_FORMATS.TIME);
    const labelOpAreaPre = opAreaPreRounded && format(opAreaPreRounded, DATE_FORMATS.TIME);
    const labelOpAreaPost = opAreaPostRounded && format(opAreaPostRounded, DATE_FORMATS.TIME);

    // durations
    const durationRoom = opExitRounded && opEnterRounded ? diffDT(opExitRounded, opEnterRounded, "seconds") : 0;
    const durationEnter = timestamps.duraRoomLockPre.refEnd - timestamps.duraRoomLockPre.refStart; // rounding not necessary
    const durationOp = opEndRounded && opStartRounded ? diffDT(opEndRounded, opStartRounded, "seconds") : 0;
    const durationExit = timestamps.duraRoomLockPost.refEnd - timestamps.duraRoomLockPost.refStart; // rounding not necessary

    let durationAreaPost;
    if (
        isUndefinedOrNull(timestamps.duraNoLockPost?.refStart) ||
        isUndefinedOrNull(timestamps.duraNoLockPost?.refEnd) ||
        isUndefinedOrNull(opExitRounded) ||
        isUndefinedOrNull(opAreaPostRounded)
    ) {
        durationAreaPost = 0;
    } else {
        durationAreaPost = diffDT(opAreaPostRounded, opExitRounded, "seconds");
    }

    let durationAreaPre;
    if (
        isUndefinedOrNull(timestamps.duraNoLockPre?.refStart) ||
        isUndefinedOrNull(timestamps.duraNoLockPre?.refEnd) ||
        isUndefinedOrNull(opAreaPreRounded) ||
        isUndefinedOrNull(opEnterRounded)
    ) {
        durationAreaPre = 0;
    } else {
        durationAreaPre = diffDT(opEnterRounded, opAreaPreRounded, "seconds");
    }

    const durationArea = durationAreaPre + durationRoom + durationAreaPost;

    const durationRoomMin = Math.floor(durationRoom / 60);
    const durationOpMin = Math.floor(durationOp / 60);
    const durationAreaMin = Math.floor(durationArea / 60);

    const durations = {};
    durations.opRoom = t("OpDetails.hhmm", {hh: Math.floor(durationRoomMin / 60), mm: durationRoomMin % 60});
    durations.op = t("OpDetails.hhmm", {hh: Math.floor(durationOpMin / 60), mm: durationOpMin % 60});
    durations.opArea = t("OpDetails.hhmm", {hh: Math.floor(durationAreaMin / 60), mm: durationAreaMin % 60});

    // calculate percentage
    const widthOp = (durationOp * 100) / durationArea;
    const widthOpEnter = (durationEnter * 100) / durationArea;
    const widthOpExit = (durationExit * 100) / durationArea;
    const widthAreaPre = (durationAreaPre * 100) / durationArea;
    const widthAreaPost = (durationAreaPost * 100) / durationArea;

    const TIME_DATA = [
        {
            exists: !isUndefinedOrNull(timestamps.duraNoLockPre?.refStart),
            starts: labelOpAreaPre,
            label: t("OpDetails.legendAreaPre"),
            color: "backgroundArea",
            width: `${widthAreaPre}%`
        },
        {
            exists: labelOpEnter,
            starts: labelOpEnter,
            label: t("OpDetails.legendEnter"),
            color: "backgroundEnter",
            width: `${widthOpEnter}%`
        },
        {exists: labelOpStart, starts: labelOpStart, label: t("OpDetails.legendOp"), color: "backgroundOp", width: `${widthOp}%`},
        {exists: labelOpEnd, starts: labelOpEnd, label: t("OpDetails.legendExit"), color: "backgroundExit", width: `${widthOpExit}%`},
        {
            exists: !isUndefinedOrNull(timestamps.duraNoLockPost?.refEnd),
            starts: labelOpExit,
            label: t("OpDetails.legendAreaPost"),
            color: "backgroundArea",
            width: `${widthAreaPost}%`
        }
    ];
    /**
     * Adds left separation and better typing for `exists` key
     * @param {object[]} data - The TIME_DATA
     * @return {{exists: boolean, starts: string, label: string, color: string, width: string, separation: string}[]}
     */
    const formatData = (data) => {
        let separation = 0;
        const dataFormatted = data.map((item) => {
            const itemWithMargin = {...item, separation: `${separation}%`, exists: Boolean(item.exists)};
            separation += parseFloat(item.width);
            return itemWithMargin;
        });
        return dataFormatted;
    };

    const opArea = details._status === completedStatus && (
        <Fragment>
            <div className={cx(classes.label, classes.bold)}>{t("OpDetails.opArea")}</div>
            <div className={classes.value}>{durations.opArea}</div>
        </Fragment>
    );

    return (
        <div data-testid="durationTabContent">
            {/* 3-parts time breakdown */}
            <div className={classes.rowTime}>
                {opArea && <div className={classes.rowTimeItem}>{opArea}</div>}
                <div className={classes.rowTimeItem}>
                    <div className={cx(classes.label, classes.bold)}>{t("OpDetails.opRoom")}</div>
                    <div className={classes.value}>{durations.opRoom}</div>
                </div>
                <div className={classes.rowTimeItem}>
                    <div className={cx(classes.label, classes.bold)}>{t("OpDetails.legendOp")}</div>
                    <div className={classes.value} data-testid="surgeryDurationValue">
                        {durations.op}
                    </div>
                </div>
                {additionalOpInfo && additionalOpInfo.includes(CPB) && (
                    <div className={classes.rowTimeItem}>
                        <div className={cx(classes.label, classes.bold)}>{t("OpDetails.legendCpb")}</div>
                        <Cpb duraCpb={timestamps?.duraCpb} isDurationBold={false} isLabelVisible={false} styles={classes.cpb} />
                    </div>
                )}
            </div>
            <Box borderBottom={1} className={classes.borderBottom} style={{marginBottom: "9px", marginTop: "16px"}} />
            {/* Start and End times */}
            <div className={cx(classes.rowTime, classes.rowStartEnd)}>
                <div className={cx(classes.bold)}>
                    {t("OpDetails.start")} {timestamps.duraNoLockPre?.refStart ? labelOpAreaPre : labelOpEnter}
                </div>
                <div className={cx(classes.bold)}>
                    {t("OpDetails.end")} {timestamps.duraNoLockPost?.refEnd ? labelOpAreaPost : labelOpExit}
                </div>
            </div>
            <Box borderBottom={1} className={classes.borderBottom} my={0} />
            {/* Time bars */}
            <div className={classes.graphicContainer}>
                {formatData(TIME_DATA).map(({exists, starts, label, color, width, separation}, i) => {
                    return (
                        exists && (
                            <div className={classes.graphicRow} key={starts}>
                                <div className={classes.graphicRowLabelsCell}>
                                    <p className={classes.graphicRowStart}>{starts}</p>
                                    <p className={classes.graphicRowLabel}>{label}</p>
                                </div>
                                <div className={cx(classes.graphicRowLabelBarWrapper, i % 2 === 0 && classes.graphicRowEven)}>
                                    <div style={{width: separation}}></div>
                                    <div className={cx(classes.graphicRowBar, classes[color])} style={{width}}></div>
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};
