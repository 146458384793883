import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    leftLabel: {
        paddingTop: "0.2rem",
        height: "48%"
    },
    title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: "50%",
        whiteSpace: "nowrap"
    },
    labelSecondary: {
        fontWeight: theme.typography.fontWeightLight,
        flexShrink: 0,
        color: theme.palette.grey[500],
        fontSize: "0.75rem",
        marginBottom: theme.spacing("s")
    },

    row: {
        display: "flex",
        fontSize: "0.75rem",
        justifyContent: "space-between"
    },
    rowAddingFactors: {
        display: "flex",
        fontSize: "0.75rem"
    },
    totalFactors: {marginRight: "10px"},
    leftOverTime: {
        marginLeft: "15px"
    },
    popupText: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    popupLabel: {
        fontWeight: "bold",
        textDecoration: "underline"
    },
    plusText: {
        color: theme.palette.success.main
    },
    minusText: {
        color: theme.palette.error.main
    },
    factorsList: {
        listStylePosition: "outside",
        fontSize: "0.6rem",
        paddingLeft: "25px"
    },
    popupMargin: {
        marginRight: "0.5rem"
    }
}));
